var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { staticClass: "mb-4", attrs: { color: "light" } },
        [
          _c(
            "v-toolbar-title",
            [
              _vm._t("back", function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        router: "",
                        to: _vm.backLink,
                        exact: ""
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _c("v-toolbar-title", { staticClass: "font-weight-light" }, [
            _vm._v("Domain Workspace")
          ]),
          _vm._t("subtitle", function() {
            return [
              _vm.subtitle != undefined && _vm.subtitle !== ""
                ? _c(
                    "v-toolbar-title",
                    { staticClass: "font-weight-light" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mx-2", attrs: { small: "" } },
                        [_vm._v("mdi-slash-forward")]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.subtitle))])
                    ],
                    1
                  )
                : _vm._e()
            ]
          }),
          _vm._t("subsubtitle", function() {
            return [
              _vm.subsubtitle != undefined && _vm.subsubtitle !== ""
                ? _c(
                    "v-toolbar-title",
                    { staticClass: "font-weight-light" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mx-2", attrs: { small: "" } },
                        [_vm._v("mdi-slash-forward")]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.subsubtitle))])
                    ],
                    1
                  )
                : _vm._e()
            ]
          }),
          _c("v-spacer"),
          _c("div", [_vm._t("content")], 2),
          _vm._t("default")
        ],
        2
      ),
      _c("domain-dialog", { ref: "domainDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }