































import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import { useDomainApi, DomainModel } from '@/module/api/domain';

const {
  selectedItem: selectedDomain,
  getItem: getDomain,
  selectItem: selectDomain
} = useDomainApi();

const domainDialog = ref<any>(null);

const viewDomain = () => {
  domainDialog.value.view(selectedDomain.value);
};

const initializeData = async (domainUuid: string) => {
  if (!domainUuid) {
    Vue.$log.debug(`Initializing DomainWorkspaceToolbar with NO domain`);
    return;
  }
  Vue.$log.debug(`Initializing DomainWorkspaceToolbar with domain: ${domainUuid}`);
  if (selectedDomain.value?.uuid != domainUuid) {
    getDomain(domainUuid).then((res: DomainModel) => {
      selectDomain(domainUuid);
    });
  }
};

export default defineComponent({
  name: 'DomainWorkspaceToolbar',
  components: { DomainDialog },
  props: ['uuid', 'backLink', 'subtitle', 'subsubtitle'],
  setup(props) {
    initializeData(props.uuid);

    return {
      domainDialog,
      viewDomain,
      selectedDomain
    };
  }
});
